import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Layout from '../layout'
import Login from '../pages/Login'
import Home from '../pages/Home'
import Users from '../pages/Users'
import UsersCreate from '../pages/Users/Create'
import UsersUpdate from '../pages/Users/Update'

import Checker from '../pages/Checker'
import Profile from '../pages/Checker/Profile'
import CheckerStatus from '../pages/Checker/Status'
import Branch from '../pages/Branch'

const role = parseInt(sessionStorage.getItem('role'))

const App = ({ loggedIn }) => {
  if (loggedIn) {
    if (role <= 1) {
      return (
        <BrowserRouter>
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/checker/profile" element={<Profile />} />
              <Route path="/branch" element={<Branch />} />
              <Route path="/checker" element={<Checker />} />
              <Route
                path="/checker/status"
                element={<CheckerStatus />}
              />
              <Route path="/users" element={<Users />} />
              <Route path="/users/create" element={<UsersCreate />} />
              <Route path="/users/:id" element={<UsersUpdate />} />
              <Route path="/login" element={<Login />} />
            </Routes>
          </Layout>
        </BrowserRouter>
      )
    }

    return (
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Profile />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    )
  } else {
    return (
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="*" element={<Login />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    )
  }
}

export default App
