export default function reducer(state = {}, action) {
  switch (action.type) {
    case 'GET_CHECKER':
      return {
        ...state,
        checker: action.payload.data,
      }
    case 'GET_CHECKER_TODAY':
      return {
        ...state,
        checkerToday: action.payload.data,
      }
    case 'GET_CHECKER_CONFIG':
      return {
        ...state,
        checkerConfig: action.payload.data,
      }
    case 'CREATE_CHECKER':
      return {
        ...state,
      }
    case 'UPDATE_CHECKER':
      return {
        ...state,
      }
    case 'DELETE_CHECKER':
      return {
        ...state,
      }
    default:
      return state
  }
}
