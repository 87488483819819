import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  setTitle,
  getAll,
  create,
  update,
  logOut,
} from '../../actions'
import styled from 'styled-components'
import Card from '../../components/Card'
import profile from '../../assets/static/profile.png'
import Button from '../../components/Button'
import Swal from 'sweetalert2'
import Tab from '../../components/Tab'
import Input from '../../components/Input'

const Profile = props => {
  const [passwordVerify, setPasswordVerify] = useState(false)
  const [password, setPassword] = useState('')

  const { setTitle, user, checkerToday } = props

  useEffect(() => {
    const topbar = {
      title: 'Checador de Hoy',
    }
    setTitle(topbar)
    props.getAll('checker/profile', 'GET_CHECKER_TODAY')

    // eslint-disable-next-line
  }, [])

  const handleCheck = () => {
    props.create('checker', 'POST_CHECKER', { 1: 1 }).then(() => {
      props.getAll('checker/profile', 'GET_CHECKER_TODAY')
    })
  }

  const handlePasswordVerify = e => {
    if (password === e.target.value) {
      setPasswordVerify(true)
    } else {
      setPasswordVerify(false)
    }
  }

  const handleCheckUpdate = (checkerId, column) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Este proceso no se puede revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok',
    }).then(result => {
      if (result.isConfirmed) {
        props
          .update(`checker/${checkerId}`, 'PUT_CHEKER', {
            column,
          })
          .then(() => {
            props.getAll('checker/profile', 'GET_CHECKER_TODAY')
          })
        Swal.fire('Actualizado!', 'Actualizado con exito.', 'success')
      }
    })
  }

  const handleSubmit = () => {
    if (passwordVerify === true) {
      props
        .update(`users/password/${user.id}`, 'PUT_USER', {
          password,
        })
        .then(() => {
          Swal.fire(
            'Actualizado!',
            'Actualizado con exito. Reinicia sesión para ver los cambios',
            'success'
          ).then(result => {
            if (result.isConfirmed) {
              sessionStorage.clear()
              props.logOut({})
              window.location.href = '/login'
            }
          })
        })
    }
  }

  if (checkerToday) {
    return (
      <>
        <Card>
          <MainStyled>
            <ProfileInfo>
              <Img src={profile} alt="" />
              <Info>
                <H4>{user.name}</H4>
              </Info>
            </ProfileInfo>
            <ButtonBar>
              {checkerToday.end_food === null ? (
                checkerToday.start !== undefined ? (
                  checkerToday.start_food !== null ? (
                    <Button
                      color="warning"
                      onClick={() =>
                        handleCheckUpdate(checkerToday.id, 'end_food')
                      }
                    >
                      Checar Fin Comida
                    </Button>
                  ) : (
                    <Button
                      color="info"
                      onClick={() =>
                        handleCheckUpdate(
                          checkerToday.id,
                          'start_food'
                        )
                      }
                    >
                      Checar Inicio Comida
                    </Button>
                  )
                ) : null
              ) : null}
              {checkerToday.end === null ||
              checkerToday.end === undefined ? (
                Object.keys(checkerToday).length > 0 ? (
                  <Button
                    onClick={() =>
                      handleCheckUpdate(checkerToday.id, 'end')
                    }
                  >
                    Checar Salida
                  </Button>
                ) : (
                  <Button onClick={handleCheck} color={'success'}>
                    Checar Entrada
                  </Button>
                )
              ) : (
                <span>Hasta mañana :)</span>
              )}
            </ButtonBar>
          </MainStyled>
        </Card>
        <Tab title="Cambiar contraseña">
          <Input
            label="Nueva Contraseña"
            type="password"
            name="password"
            placeholder="Contraseña"
            onChange={e => {
              setPassword(e.target.value)
            }}
          />
          <Input
            label="Confirmar contraseña"
            type="password"
            name="password"
            placeholder="Confirmar Contraseña"
            onChange={e => {
              handlePasswordVerify(e)
            }}
          />
          {password !== '' ? (
            passwordVerify ? (
              <Span pass={passwordVerify}>Password Correcto</Span>
            ) : (
              <Span pass={passwordVerify}>
                Los password deben ser iguales
              </Span>
            )
          ) : null}
          <div>
            <Button color="success" onClick={handleSubmit}>
              Cambiar
            </Button>
          </div>
        </Tab>
      </>
    )
  } else {
    return <div>Cargando...</div>
  }
}

const mapStateToProps = state => {
  return {
    user: state.reducerApp.user,
    checkerToday: state.checker.checkerToday,
  }
}

const mapDispatchToProps = {
  setTitle,
  getAll,
  create,
  update,
  logOut,
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)

const MainStyled = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const ProfileInfo = styled.div`
  display: flex;
`
const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 400;
`

const H4 = styled.h4`
  font-size: 1.2rem;
  margin: 0;
  margin-bottom: 0.5rem;
  font-weight: 400;
`

const Img = styled.img`
  width: 150px;
  height: 150px;
  object-fit: contain;
  border-radius: 50%;
  margin-right: 2rem;
  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`

const ButtonBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin: 0 0.5rem;
  }
  @media (max-width: 768px) {
    margin-top: 1.5rem;
  }
`

const Span = styled.span`
  color: ${props =>
    props.pass ? props.theme.success : props.theme.danger};
  font-size: 0.875rem;
  line-height: 2.5;
`
