const initialState = {
  topbar: { title: '', menu: { Menu: '/' } },
  loggedIn: false,
  wraper: false,
  user: {
    user: sessionStorage.getItem('user'),
    name: sessionStorage.getItem('name'),
    id: sessionStorage.getItem('id'),
    role: sessionStorage.getItem('role'),
    BranchCD: sessionStorage.getItem('BranchCD'),
    department: sessionStorage.getItem('department'),
  },
  themeApp: {
    theme: 'light',
    lightTheme: {
      body: '#F0F1F8',
      text: '#000',
      colorButtonCircle: '#404C77',
      boxShadow: '0px 2px 3px rgba(4, 4, 7, 0.1)',
      backgroundButton: '#FFF',
      border: '1px solid #eaedf1',
      colorSublink: '#6A788E',
      primary: '#D01B1C',
      primary_scale1: 'rgba(231, 50, 19, 0.6)',
      primary_scale2: 'rgba(222, 77, 18, 0.2)',
      menu_category: 'rgb(138, 152, 172)',
      menu_link_active: '#FFF',
    },
    darkTheme: {
      body: '#1F1F34',
      text: '#fff',
      colorButtonCircle: '#fff',
      boxShadow: '0px 2px 3px #1a1a2f',
      backgroundButton: '#2A2B43',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      colorSublink: '#fff',
      primary: '#D01B1C',
      primary_scale1: 'rgba(231, 50, 19, 0.6)',
      primary_scale2: 'rgba(222, 77, 18, 0.2)',
      menu_category: 'rgb(138, 152, 172)',
      menu_link_active: '#FFF',
    },
  },
  localization: {
    pagination: {
      labelDisplayedRows: '{from}-{to} de {count}',
      labelRowsSelect: 'Filas',
      firstAriaLabel: 'Primera',
      firstTooltip: 'Primera',
      previousAriaLabel: 'Anterior',
      previousTooltip: 'Anterior',
      nextAriaLabel: 'Siguiente',
      nextTooltip: 'Siguiente',
      lastAriaLabel: 'Ultimo',
      lastTooltip: 'Ultimo',
    },
    toolbar: {
      searchTooltip: 'Buscar',
      searchPlaceholder: 'Buscar',
    },
    header: {
      actions: 'Acciones',
    },
    body: {
      editRow: {
        deleteText: '¿Eliminar?',
        saveTooltip: 'Ok',
        cancelTooltip: 'Cancelar',
      },
      editTooltip: 'Editar',
      deleteTooltip: 'Eliminar',
      addTooltip: 'Agregar',
    },
  },
}

export default function reducerApp(state = initialState, action) {
  switch (action.type) {
    case 'SET_TITLE':
      return {
        ...state,
        topbar: action.payload,
      }
    case 'SET_THEME':
      return {
        ...state,
        themeApp: action.payload,
      }
    case 'LOGIN_REQUEST':
      return {
        ...state,
        loggedIn: true,
      }
    case 'LOG_OUT':
      return {
        ...state,
        loggedIn: false,
      }
    default:
      return state
  }
}
