import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setTitle, getAll, update } from '../../actions'
import Card from '../../components/Card'
import moment from 'moment-timezone'
import 'moment/locale/es-mx'
import styled from 'styled-components'
import Input from '../../components/Input'
const Users = props => {
  moment.locale('es')
  moment.tz.setDefault('America/Mexico_City')

  const { checker, users, setTitle, checkerConfig, branch } = props
  const [branchSelected, setBranch] = useState(0)
  const [name, setName] = useState('')

  useEffect(() => {
    const topbar = {
      title: 'Estatus Usuarios',
    }
    setTitle(topbar)
    props
      .getAll('checker', 'GET_CHECKER')
      .then(() => {
        props.getAll('checker/config', 'GET_CHECKER_CONFIG')
      })
      .then(() => {
        props.getAll('users', 'GET_USERS')
      })
      .then(() => {
        props.getAll('branch', 'GET_BRANCH')
      })
    // eslint-disable-next-line
  }, [])

  if (checker && users && checkerConfig && branch) {
    const dataUsers =
      branchSelected > 0
        ? users.filter(
            u => u.branch_id === branchSelected && u.role !== 0
          )
        : users.filter(u => u.role !== 0)

    const data =
      name !== ''
        ? dataUsers.filter(item =>
            item.name
              .concat(item.lastname, item.lastname2)
              .toLowerCase()
              .includes(name.toLowerCase())
          )
        : dataUsers

    const handleSpecialStatus = (status, userId, checkerId) => {
      props
        .update(`checker/special`, 'PUT_CHEKER', {
          status,
          user_id: userId,
          checker_id: checkerId,
        })
        .then(() => {
          props.getAll('checker', 'GET_CHECKER')
        })
        .then(() => {
          props.getAll('users', 'GET_USERS')
        })
    }

    return (
      <>
        <div>
          <Card>
            <Grid>
              <Input
                type="text"
                name="name"
                title="Buscar"
                onChange={e => setName(e.target.value)}
              />
              <Select
                onChange={e => setBranch(parseInt(e.target.value))}
              >
                <option value="0">Todos</option>
                {branch.map(b => (
                  <option value={b.id}> {b.name}</option>
                ))}
              </Select>
            </Grid>
          </Card>

          {data.map(user => {
            let status = 'Presente'

            const config = checkerConfig.find(
              c =>
                c.id ===
                branch.find(b => b.id === user.branch_id).config_id
            )

            const todayCheck = checker.find(
              c =>
                c.user_id === user.id &&
                moment().format('YYYY-MM-DD') ===
                  moment(c.start).format('YYYY-MM-DD')
            )

            const today = moment(),
              todayTime = moment(config.start, 'HH:mm:ss')

            today.set({
              hour: todayTime.get('hour'),
              minute: todayTime.get('minute'),
              second: todayTime.get('second'),
            })

            const todayTolerance = moment(
              today.add(config.tolerance, 'minutes')
            )

            const todayMaxTolerance = moment(
              today.add(config.tolerance, 'minutes')
            )

            const isLate =
              todayCheck !== undefined &&
              todayCheck.start !== undefined &&
              todayCheck.start !== null
                ? todayTolerance.isBefore(moment(todayCheck.start))
                : false

            const isToLate =
              todayCheck !== undefined &&
              todayCheck.start !== undefined &&
              todayCheck.start !== null
                ? todayMaxTolerance.isBefore(moment(todayCheck.start))
                : false

            if (isLate) {
              status = 'Retardo'
            }

            if (
              todayCheck !== undefined &&
              todayCheck.start_food !== undefined &&
              todayCheck.start_food !== null &&
              todayCheck.end_food === null
            ) {
              status = 'Comedor'
            }

            if (todayCheck === undefined || isToLate === true) {
              status = 'Falta Injustificada'
            }

            if (
              todayCheck !== undefined &&
              todayCheck.special_status !== null
            ) {
              status = todayCheck.special_status
            }

            return (
              <Card
                title={`${user.lastname} ${user.lastname2} ${user.name}`}
                key={user.id}
              >
                <Table>
                  <Thead>
                    <tr>
                      <Th>Fecha</Th>
                      <Th>Entrada</Th>
                      <Th>Salida</Th>
                      <Th>Entrada Comida</Th>
                      <Th>Salida Comida</Th>
                      <Th>Estatus</Th>
                    </tr>
                  </Thead>
                  <tbody>
                    <tr status={status}>
                      <Td>{moment().format('YYYY-MM-DD')}</Td>
                      <Td late={isLate} toLate={isToLate}>
                        {todayCheck &&
                        todayCheck.start !== undefined &&
                        todayCheck.start !== null
                          ? moment(todayCheck.start).format(
                              'HH:mm:ss'
                            )
                          : '-'}
                      </Td>
                      <Td>
                        {todayCheck &&
                        todayCheck.end !== undefined &&
                        todayCheck.end !== null
                          ? moment(todayCheck.end).format('HH:mm:ss')
                          : '-'}
                      </Td>
                      <Td>
                        {todayCheck &&
                        todayCheck.start_food !== undefined &&
                        todayCheck.start_food !== null
                          ? moment(todayCheck.start_food).format(
                              'HH:mm:ss'
                            )
                          : '-'}
                      </Td>
                      <Td>
                        {todayCheck &&
                        todayCheck.end_food !== undefined &&
                        todayCheck.end_food !== null
                          ? moment(todayCheck.end_food).format(
                              'HH:mm:ss'
                            )
                          : '-'}
                      </Td>
                      <Td status={status}>
                        <SelectStatus
                          onChange={e =>
                            handleSpecialStatus(
                              e.target.value,
                              user.id,
                              todayCheck?.id
                            )
                          }
                          value={status}
                        >
                          <option value="0">{status}</option>
                          <option value="1">Por defecto</option>
                          <option value="Permiso con Goce">
                            Permiso con Goce
                          </option>
                          <option value="Permiso sin Goce">
                            Permiso sin Goce
                          </option>
                          <option value="Falta Justificada">
                            Falta Justificada
                          </option>
                        </SelectStatus>
                      </Td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            )
          })}
        </div>
      </>
    )
  } else {
    return <div>Cargando...</div>
  }
}

const mapStateToProps = state => {
  return {
    checker: state.checker.checker,
    checkerConfig: state.checker.checkerConfig,
    users: state.users.users,
    branch: state.branch.branch,
    themeApp: state.reducerApp.themeApp,
    localization: state.reducerApp.localization,
  }
}

const mapDispatchToProps = {
  setTitle,
  getAll,
  update,
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)

const Table = styled.table`
  width: 100%;
  border: ${({ theme }) => theme.border};
`
const Thead = styled.thead`
  border: ${({ theme }) => theme.border};
`

const Th = styled.th`
  text-transform: uppercase;
  padding: 0.75rem;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: left;
`
const Td = styled.td`
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0;
  font-size: 0.8rem;
  color: ${props =>
    props.toLate
      ? props.theme.danger
      : props.late
      ? props.theme.warning
      : props.status === 'Falta Injustificada'
      ? props.theme.white
      : props.status === 'Presente'
      ? props.theme.white
      : props.status
      ? props.theme.white
      : ''};
  background-color: ${props =>
    props.status === 'Falta Injustificada'
      ? props.theme.danger
      : props.status === 'Presente'
      ? props.theme.success
      : props.status === 'Comedor'
      ? props.theme.info
      : props.status === 'Retardo'
      ? props.theme.warning
      : props.status
      ? props.theme.success
      : ''};
`

const Select = styled.select`
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  outline-color: ${({ theme }) => theme.border};
  color: var(--dark);
  background-color: var(--white);
  background-clip: padding-box;
  border-radius: 3px;
  border: ${({ theme }) => theme.border};
  box-shadow: none;
  margin-top: 0.375rem;
  border-style: solid;
  border-radius: 7px;
  transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
`
const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  justify-content: space-around;
`

const SelectStatus = styled(Select)`
  width: 100%;
  margin: 0;
  box-sizing: border-box;
`
