import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { setTitle, getAll, deleted } from '../../actions'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Card from '../../components/Card'
import moment from 'moment'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
)
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
    },
  },
  interaction: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
}

const Home = props => {
  const { setTitle, users, checker, checkerConfig, branch } = props

  useEffect(() => {
    const topbar = {
      title: 'Dashboard',
    }
    setTitle(topbar)
    props
      .getAll('checker', 'GET_CHECKER')
      .then(() => {
        props.getAll('checker/config', 'GET_CHECKER_CONFIG')
      })
      .then(() => {
        props.getAll('users', 'GET_USERS')
      })
      .then(() => {
        props.getAll('branch', 'GET_BRANCH')
      })

    // eslint-disable-next-line
  }, [])

  if (!users || !checker || !checkerConfig || !branch) {
    return <div>Cargando...</div>
  }

  const timeExport = date => {
    const exportWeek = []
    const startDate = date
    const endDate = moment().add(1, 'days')
    let i = 1

    while (startDate < endDate) {
      exportWeek.push({ id: i, day: moment(startDate) })
      startDate.add(1, 'days')
      i++
    }

    const dataExport = users
      .filter(u => u.role !== 0)
      .map(user => {
        const config = checkerConfig.find(
          c =>
            c.id ===
            branch.find(b => b.id === user.branch_id).config_id
        )

        return exportWeek.map(d => {
          const todayCheck = checker.find(
            c =>
              c.user_id === user.id &&
              d.day.format('YYYY-MM-DD') ===
                moment(c.start).format('YYYY-MM-DD')
          )

          const today = moment(d.day),
            todayTime = moment(config.start, 'HH:mm:ss')

          today.set({
            hour: todayTime.get('hour'),
            minute: todayTime.get('minute'),
            second: todayTime.get('second'),
          })

          const todayTolerance = moment(
            today.add(config.tolerance, 'minutes')
          )

          const todayMaxTolerance = moment(
            today.add(config.tolerance, 'minutes')
          )

          const isLate =
            todayCheck !== undefined &&
            todayCheck.start !== undefined &&
            todayCheck.start !== null
              ? todayTolerance.isBefore(moment(todayCheck.start))
              : false

          const isToLate =
            todayCheck !== undefined &&
            todayCheck.start !== undefined &&
            todayCheck.start !== null
              ? todayMaxTolerance.isBefore(moment(todayCheck.start))
              : false

          let status = 'Presente'

          if (isLate) {
            status = 'Retardo'
          }

          if (
            todayCheck !== undefined &&
            todayCheck.start_food !== undefined &&
            todayCheck.start_food !== null &&
            todayCheck.end_food === null
          ) {
            status = 'Comedor'
          }

          if (todayCheck === undefined || isToLate === true) {
            status = 'Falta Injustificada'
          }

          if (
            todayCheck !== undefined &&
            todayCheck.special_status !== null
          ) {
            status = todayCheck.special_status
          }

          return {
            name: `${user.lastname} ${user.lastname2} ${user.name}`,
            date: d.day.format('YYYY-MM-DD'),
            day: d.day.format('dddd'),
            schedule: `${config.start.substring(
              0,
              config.start.length - 3
            )} - ${config.end.substring(0, config.end.length - 3)}`,

            start:
              todayCheck &&
              todayCheck.start !== undefined &&
              todayCheck.start !== null
                ? moment(todayCheck.start).format('HH:mm:ss')
                : '-',
            end:
              todayCheck &&
              todayCheck.end !== undefined &&
              todayCheck.end !== null
                ? moment(todayCheck.end).format('HH:mm:ss')
                : '-',
            startFood:
              todayCheck &&
              todayCheck.start_food !== undefined &&
              todayCheck.start_food !== null
                ? moment(todayCheck.start_food).format('HH:mm:ss')
                : '-',
            endFood:
              todayCheck &&
              todayCheck.end_food !== undefined &&
              todayCheck.end_food !== null
                ? moment(todayCheck.end_food).format('HH:mm:ss')
                : '-',
            status,
          }
        })
      })

    const items = []

    dataExport.map(rowData => rowData.map(row => items.push(row)))

    return items
  }

  const dataChart = () => {
    let dataAll = timeExport(moment().subtract(7, 'days'))

    //Creamos un nuevo objeto donde vamos a almacenar por ciudades.
    let nuevoObjeto = {}
    //Recorremos el arreglo
    dataAll.forEach(x => {
      //Si la ciudad no existe en nuevoObjeto entonces
      //la creamos e inicializamos el arreglo de profesionales.
      if (!nuevoObjeto.hasOwnProperty(x.status)) {
        nuevoObjeto[x.status] = {
          contador: [],
        }
      }

      //Agregamos los datos de contador.
      nuevoObjeto[x.status].contador.push({
        status: x.status,
        label: x.status,
      })
    })

    return {
      labels: ['Falta Injustificada', 'Presente', 'Retardo'],
      datasets: [
        {
          label: 'Datos',
          backgroundColor: '#3A72C6',
          borderColor: '#3A72C6',
          borderWidth: 1,
          hoverBackgroundColor: '#3A72C6',
          hoverBorderColor: '#3A72C6',
          data: Object.keys(nuevoObjeto).map(
            x => nuevoObjeto[x].contador.length
          ),
        },
      ],
    }
  }

  const data = dataChart()

  return (
    <HomeStyled>
      <Grid>
        <LinkStyled to="/stock">
          <Item color="success">
            <Info>
              <Number>
                {users.filter(u => u.role !== 0).length}
              </Number>
              <Text>Usuarios</Text>
            </Info>
          </Item>
        </LinkStyled>

        <LinkStyled to="/orders">
          <Item>
            <Info>
              <Number>
                {
                  timeExport(moment()).filter(
                    u => u.status === 'Presente'
                  ).length
                }
              </Number>
              <Text>Presentes</Text>
            </Info>
          </Item>
        </LinkStyled>
        <LinkStyled to="/customers">
          <Item color="warning">
            <Info>
              <Number>
                {
                  timeExport(moment()).filter(
                    u => u.status === 'Retardo'
                  ).length
                }
              </Number>
              <Text>Retardos</Text>
            </Info>
          </Item>
        </LinkStyled>
        <LinkStyled to="/suppliers">
          <Item color="danger">
            <Info>
              <Number>
                {
                  timeExport(moment()).filter(
                    u => u.status === 'Falta Injustificada'
                  ).length
                }
              </Number>
              <Text>Ausentes</Text>
            </Info>
          </Item>
        </LinkStyled>
      </Grid>
      <Card title={'Actividad ultimos 7 días'}>
        <Bar
          options={options}
          responsive={false}
          data={{
            labels: data.labels,
            datasets: data.datasets,
          }}
        />
      </Card>
    </HomeStyled>
  )
}

const mapStateToProps = state => {
  return {
    checker: state.checker.checker,
    checkerConfig: state.checker.checkerConfig,
    users: state.users.users,
    branch: state.branch.branch,
    user: state.reducerApp.user,
  }
}

const mapDispatchToProps = {
  setTitle,
  getAll,
  deleted,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)

const HomeStyled = styled.div`
  display: flex;
  flex-direction: column;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  padding-bottom: 1rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  img {
    max-width: 300px;
  }
`

const Item = styled.div`
  position: relative;
  color: ${props => props.theme.text};
  background-color: ${props => props.theme.backgroundButton};
  border-bottom: 4px solid
    ${props => {
      switch (props.color) {
        case 'warning':
          return props.theme.warning
        case 'danger':
          return props.theme.danger
        case 'info':
          return props.theme.info
        case 'success':
          return props.theme.success
        case 'secondary':
          return props.theme.secondary
        case 'purple':
          return props.theme.purple
        default:
          return props.theme.primary
      }
    }};

  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
`
const Info = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-left: 1rem;
`

const Number = styled.span`
  line-height: 1.1;
  font-size: 2rem;
  font-weight: 500;
`
const Text = styled.span`
  font-size: 0.9rem;
  font-weight: 100;
`

const LinkStyled = styled(Link)`
  text-decoration: none;
`
