import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setTitle, getAll, deleted } from '../../actions'
import Card from '../../components/Card'
import moment from 'moment-timezone'
import 'moment/locale/es-mx'
import styled from 'styled-components'
import Input from '../../components/Input'
import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import { exportCsv } from '../../utils/exportData'
import Tab from '../../components/Tab'
import Button from '../../components/Button'

const Users = props => {
  moment.locale('es')
  moment.tz.setDefault('America/Mexico_City')

  const { checker, users, setTitle, checkerConfig, branch } = props
  const [branchSelected, setBranch] = useState(0)
  const [time, setTime] = useState(0)
  const [name, setName] = useState('')
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    },
  ])

  useEffect(() => {
    const topbar = {
      title: 'Checador',
    }
    setTitle(topbar)
    props
      .getAll('checker', 'GET_CHECKER')
      .then(() => {
        props.getAll('checker/config', 'GET_CHECKER_CONFIG')
      })
      .then(() => {
        props.getAll('users', 'GET_USERS')
      })
      .then(() => {
        props.getAll('branch', 'GET_BRANCH')
      })
    // eslint-disable-next-line
  }, [])

  const monday = moment().weekday(1)
  const twuesday = moment().weekday(2)
  const wednesday = moment().weekday(3)
  const thursday = moment().weekday(4)
  const friday = moment().weekday(5)
  const saturday = moment().weekday(6)
  const startOfMonth = moment()
    .startOf('month')
    .format('YYYY-MM-DD HH:mm:ss')
  const halfOfMonth = moment().format('YYYY-MM-15 HH:mm:ss')
  const endOfMonth = moment()
    .endOf('month')
    .format('YYYY-MM-DD HH:mm:ss')

  let week = []
  if (time === 0) {
    week = [
      { id: 1, day: monday },
      { id: 2, day: twuesday },
      { id: 3, day: wednesday },
      { id: 4, day: thursday },
      { id: 5, day: friday },
      { id: 6, day: saturday },
    ]
  }
  if (time === 1) {
    let day = moment(startOfMonth),
      i = 1

    while (day < moment(halfOfMonth)) {
      week.push({ id: i, day: moment(day) })
      day.add(1, 'days')
      i++
    }
  }
  if (time === 2) {
    let day = moment(halfOfMonth).add(1, 'days'),
      i = 1

    while (day < moment(endOfMonth)) {
      week.push({ id: i, day: moment(day) })
      day.add(1, 'days')
      i++
    }
  }
  if (time === 3) {
    let day = moment(startOfMonth),
      i = 1

    while (day < moment(endOfMonth)) {
      week.push({ id: i, day: moment(day) })
      day.add(1, 'days')
      i++
    }
  }

  if (checker && users && checkerConfig && branch) {
    const dataUsers =
      branchSelected > 0
        ? users.filter(
            u => u.branch_id === branchSelected && u.role !== 0
          )
        : users.filter(u => u.role !== 0)

    const data =
      name !== ''
        ? dataUsers.filter(item =>
            item.name
              .concat(item.lastname, item.lastname2)
              .toLowerCase()
              .includes(name.toLowerCase())
          )
        : dataUsers

    const timeExport = () => {
      const exportWeek = []
      const startDate = moment(state[0].startDate)
      const endDate = moment(state[0].endDate)
      let i = 1

      while (startDate < endDate) {
        exportWeek.push({ id: i, day: moment(startDate) })
        startDate.add(1, 'days')
        i++
      }

      const dataExport = data.map(user => {
        const config = checkerConfig.find(
          c =>
            c.id ===
            branch.find(b => b.id === user.branch_id).config_id
        )

        return exportWeek.map(d => {
          const todayCheck = checker.find(
            c =>
              c.user_id === user.id &&
              d.day.format('YYYY-MM-DD') ===
                moment(c.start).format('YYYY-MM-DD')
          )

          const today = moment(d.day),
            todayTime = moment(config.start, 'HH:mm:ss')

          today.set({
            hour: todayTime.get('hour'),
            minute: todayTime.get('minute'),
            second: todayTime.get('second'),
          })

          const todayTolerance = moment(
            today.add(config.tolerance, 'minutes')
          )

          const todayMaxTolerance = moment(
            today.add(config.tolerance, 'minutes')
          )

          const isLate =
            todayCheck !== undefined &&
            todayCheck.start !== undefined &&
            todayCheck.start !== null
              ? todayTolerance.isBefore(moment(todayCheck.start))
              : false

          const isToLate =
            todayCheck !== undefined &&
            todayCheck.start !== undefined &&
            todayCheck.start !== null
              ? todayMaxTolerance.isBefore(moment(todayCheck.start))
              : false

          let status = 'Presente'

          if (isLate) {
            status = 'Retardo'
          }

          if (
            todayCheck !== undefined &&
            todayCheck.start_food !== undefined &&
            todayCheck.start_food !== null &&
            todayCheck.end_food === null
          ) {
            status = 'Comedor'
          }

          if (todayCheck === undefined || isToLate === true) {
            status = 'Falta Injustificada'
          }

          if (
            todayCheck !== undefined &&
            todayCheck.special_status !== null
          ) {
            status = todayCheck.special_status
          }

          return {
            name: `${user.lastname} ${user.lastname2} ${user.name}`,
            date: d.day.format('YYYY-MM-DD'),
            day: d.day.format('dddd'),
            schedule: `${config.start.substring(
              0,
              config.start.length - 3
            )} - ${config.end.substring(0, config.end.length - 3)}`,

            start:
              todayCheck &&
              todayCheck.start !== undefined &&
              todayCheck.start !== null
                ? moment(todayCheck.start).format('HH:mm:ss')
                : '-',
            end:
              todayCheck &&
              todayCheck.end !== undefined &&
              todayCheck.end !== null
                ? moment(todayCheck.end).format('HH:mm:ss')
                : '-',
            startFood:
              todayCheck &&
              todayCheck.start_food !== undefined &&
              todayCheck.start_food !== null
                ? moment(todayCheck.start_food).format('HH:mm:ss')
                : '-',
            endFood:
              todayCheck &&
              todayCheck.end_food !== undefined &&
              todayCheck.end_food !== null
                ? moment(todayCheck.end_food).format('HH:mm:ss')
                : '-',
            status,
          }
        })
      })

      return exportCsv(dataExport, startDate, endDate)
    }

    return (
      <>
        <Tab title="Generar reporte">
          <div>
            <DateRange
              editableDateInputs={true}
              onChange={item => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={state}
            />
          </div>
          <Button onClick={timeExport}>Generar Csv</Button>
        </Tab>
        <div>
          <Card>
            <Grid>
              <Input
                type="text"
                name="name"
                title="Buscar"
                onChange={e => setName(e.target.value)}
              />
              <Select
                onChange={e => setTime(parseInt(e.target.value))}
              >
                <option value="0">Semana Actual</option>
                <option value="1">Primera Quincena</option>
                <option value="2">Segunda Quincena</option>
                <option value="3">Mes</option>
              </Select>
              <Select
                onChange={e => setBranch(parseInt(e.target.value))}
              >
                <option value="0">Todos</option>
                {branch.map(b => (
                  <option value={b.id}> {b.name}</option>
                ))}
              </Select>
            </Grid>
          </Card>

          {data.map(user => {
            const config = checkerConfig.find(
              c =>
                c.id ===
                branch.find(b => b.id === user.branch_id).config_id
            )
            return (
              <Card
                title={`${user.lastname} ${user.lastname2} ${user.name}`}
                key={user.id}
              >
                <Table>
                  <Thead>
                    <tr>
                      <Th>Fecha</Th>
                      <Th>Día</Th>
                      <Th>Horario</Th>
                      <Th>Entrada</Th>
                      <Th>Salida</Th>
                      <Th>Entrada Comida</Th>
                      <Th>Salida Comida</Th>
                    </tr>
                  </Thead>
                  <tbody>
                    {week.map(d => {
                      const todayCheck = checker.find(
                        c =>
                          c.user_id === user.id &&
                          d.day.format('YYYY-MM-DD') ===
                            moment(c.start).format('YYYY-MM-DD')
                      )

                      const today = moment(d.day),
                        todayTime = moment(config.start, 'HH:mm:ss')

                      today.set({
                        hour: todayTime.get('hour'),
                        minute: todayTime.get('minute'),
                        second: todayTime.get('second'),
                      })

                      const todayTolerance = moment(
                        today.add(config.tolerance, 'minutes')
                      )

                      const todayMaxTolerance = moment(
                        today.add(config.tolerance, 'minutes')
                      )

                      const isLate =
                        todayCheck !== undefined &&
                        todayCheck.start !== undefined &&
                        todayCheck.start !== null
                          ? todayTolerance.isBefore(
                              moment(todayCheck.start)
                            )
                          : false

                      const isToLate =
                        todayCheck !== undefined &&
                        todayCheck.start !== undefined &&
                        todayCheck.start !== null
                          ? todayMaxTolerance.isBefore(
                              moment(todayCheck.start)
                            )
                          : false

                      return (
                        <tr>
                          <Td>{d.day.format('YYYY-MM-DD')}</Td>
                          <Td>{d.day.format('dddd')}</Td>
                          <Td>
                            {config.start.substring(
                              0,
                              config.start.length - 3
                            )}{' '}
                            -{' '}
                            {config.end.substring(
                              0,
                              config.end.length - 3
                            )}
                          </Td>
                          <Td late={isLate} toLate={isToLate}>
                            {todayCheck &&
                            todayCheck.start !== undefined &&
                            todayCheck.start !== null
                              ? moment(todayCheck.start).format(
                                  'HH:mm:ss'
                                )
                              : '-'}
                          </Td>
                          <Td>
                            {todayCheck &&
                            todayCheck.end !== undefined &&
                            todayCheck.end !== null
                              ? moment(todayCheck.end).format(
                                  'HH:mm:ss'
                                )
                              : '-'}
                          </Td>
                          <Td>
                            {todayCheck &&
                            todayCheck.start_food !== undefined &&
                            todayCheck.start_food !== null
                              ? moment(todayCheck.start_food).format(
                                  'HH:mm:ss'
                                )
                              : '-'}
                          </Td>
                          <Td>
                            {todayCheck &&
                            todayCheck.end_food !== undefined &&
                            todayCheck.end_food !== null
                              ? moment(todayCheck.end_food).format(
                                  'HH:mm:ss'
                                )
                              : '-'}
                          </Td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Card>
            )
          })}
        </div>
      </>
    )
  } else {
    return <div>Cargando...</div>
  }
}

const mapStateToProps = state => {
  return {
    checker: state.checker.checker,
    checkerConfig: state.checker.checkerConfig,
    users: state.users.users,
    branch: state.branch.branch,
    themeApp: state.reducerApp.themeApp,
    localization: state.reducerApp.localization,
  }
}

const mapDispatchToProps = {
  setTitle,
  getAll,
  deleted,
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)

const Table = styled.table`
  width: 100%;
  border: ${({ theme }) => theme.border};
`
const Thead = styled.thead`
  border: ${({ theme }) => theme.border};
`
const Th = styled.th`
  text-transform: uppercase;
  padding: 0.75rem;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: left;
`
const Td = styled.td`
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0;
  font-size: 0.8rem;
  color: ${props =>
    props.toLate
      ? props.theme.danger
      : props.late
      ? props.theme.warning
      : ''};
`

const Select = styled.select`
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  outline-color: ${({ theme }) => theme.border};
  color: var(--dark);
  background-color: var(--white);
  background-clip: padding-box;
  border-radius: 3px;
  border: ${({ theme }) => theme.border};
  box-shadow: none;
  margin-top: 0.375rem;
  border-style: solid;
  border-radius: 7px;
  transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
`
const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
  justify-content: space-around;
`
