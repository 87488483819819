import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'
import { setTitle, getAll, deleted } from '../../actions'
import Swal from 'sweetalert2'
import Button from '../../components/Button'
import MaterialTable from 'material-table'

const Users = props => {
  const { users, branch, setTitle, localization, themeApp, user } =
    props

  useEffect(() => {
    const topbar = {
      title: 'Usuarios',
      buttonBar: (
        <Link to="/users/create">
          <Button>Crear</Button>
        </Link>
      ),
    }
    setTitle(topbar)
    props.getAll('users', 'GET_USERS').then(() => {
      props.getAll('branch', 'GET_BRANCH')
    })
    // eslint-disable-next-line
  }, [])

  const handleDeleteUser = userId => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Este proceso no se puede revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, borrar',
    }).then(result => {
      if (result.isConfirmed) {
        props.deleted(`users/${userId}`, 'DELETE_USER').then(() => {
          props.getAll('users', 'GET_USERS')
        })
        Swal.fire('Borrado!', 'Borrado con exito.', 'success')
      }
    })
  }

  if (!branch) {
    return <h1>Cargando...</h1>
  }

  const lookBranch = []
  branch.map(item => (lookBranch[item.id] = item.name))

  const columnsAdmin = [
    { title: 'N.S.S', field: 'nss' },
    { title: 'RFC', field: 'rfc' },
    { title: 'CURP', field: 'name' },
    { title: 'Apellido Paterno', field: 'lastname' },
    { title: 'Apellido Materno', field: 'lastname2' },
    { title: 'Nombre(s)', field: 'name' },
    { title: 'Puesto', field: 'position' },
    { title: 'Usuario', field: 'user' },
    {
      title: 'Departamento',
      field: 'department',
    },
    {
      title: 'Rol',
      field: 'role',
      lookup: {
        0: 'Dev',
        1: 'Administrador',
        2: 'Usuario',
      },
    },

    {
      title: 'Sucursal',
      field: 'branch_id',
      lookup: lookBranch,
    },
    {
      title: 'Automatización',
      field: 'auto',
      lookup: {
        0: 'No',
        1: 'Si',
      },
    },
    {
      title: 'Acciones',
      field: 'id',
      render: rowData =>
        rowData.id > 1 ? (
          <>
            <Link to={`/users/${rowData.id}`}>
              <Button className="btn --warning">
                <AiOutlineEdit />
              </Button>
            </Link>
            <Button
              className="btn --danger"
              onClick={() => handleDeleteUser(rowData.id)}
            >
              <AiOutlineDelete />
            </Button>
          </>
        ) : null,
      export: false,
    },
  ]

  return (
    <>
      <MaterialTable
        columns={
          parseInt(user.role) === 0
            ? columnsAdmin
            : [
                { title: 'N.S.S', field: 'nss' },
                { title: 'RFC', field: 'rfc' },
                { title: 'CURP', field: 'name' },
                { title: 'Apellido Paterno', field: 'lastname' },
                { title: 'Apellido Materno', field: 'lastname2' },
                { title: 'Nombre(s)', field: 'name' },
                { title: 'Puesto', field: 'position' },
                { title: 'Usuario', field: 'user' },
                {
                  title: 'Departamento',
                  field: 'department',
                },
                {
                  title: 'Rol',
                  field: 'role',
                  lookup: {
                    0: 'Dev',
                    1: 'Administrador',
                    2: 'Usuario',
                  },
                },

                {
                  title: 'Sucursal',
                  field: 'branch_id',
                  lookup: lookBranch,
                },

                {
                  title: 'Acciones',
                  field: 'id',
                  render: rowData =>
                    rowData.id > 1 ? (
                      <>
                        <Link to={`/users/${rowData.id}`}>
                          <Button className="btn --warning">
                            <AiOutlineEdit />
                          </Button>
                        </Link>
                        <Button
                          className="btn --danger"
                          onClick={() => handleDeleteUser(rowData.id)}
                        >
                          <AiOutlineDelete />
                        </Button>
                      </>
                    ) : null,
                  export: false,
                },
              ]
        }
        localization={localization}
        data={users.filter(u => u.role !== 0)}
        title="Lista de Usuarios"
        options={{
          filtering: true,
          pageSize: 50,
          pageSizeOptions: [50, 100, 150],
          emptyRowsWhenPaging: false,
          filterCellStyle: {
            backgroundColor: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.backgroundButton
                : themeApp.darkTheme.backgroundButton
            }`,
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          headerStyle: {
            backgroundColor: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.backgroundButton
                : themeApp.darkTheme.backgroundButton
            }`,
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          rowStyle: {
            backgroundColor: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.backgroundButton
                : themeApp.darkTheme.backgroundButton
            }`,
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          actionsCellStyle: {
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          searchFieldStyle: {
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
          detailPanelColumnStyle: {
            backgroundColor: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.backgroundButton
                : themeApp.darkTheme.backgroundButton
            }`,
            color: `${
              themeApp.theme === 'light'
                ? themeApp.lightTheme.colorButtonCircle
                : themeApp.darkTheme.colorButtonCircle
            }`,
          },
        }}
        style={{
          backgroundColor: `${
            themeApp.theme === 'light'
              ? themeApp.lightTheme.backgroundButton
              : themeApp.darkTheme.backgroundButton
          }`,
          color: `${
            themeApp.theme === 'light'
              ? themeApp.lightTheme.colorButtonCircle
              : themeApp.darkTheme.colorButtonCircle
          }`,
        }}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    user: state.reducerApp.user,
    users: state.users.users,
    branch: state.branch.branch,
    themeApp: state.reducerApp.themeApp,
    localization: state.reducerApp.localization,
  }
}

const mapDispatchToProps = {
  setTitle,
  getAll,
  deleted,
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
