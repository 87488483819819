import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { create, getAll, setTitle } from '../../actions'
import Card from '../../components/Card'
import Input from '../../components/Input'
import Button from '../../components/Button'
import styled from 'styled-components'

const CreateUser = props => {
  const [passwordVerify, setPasswordVerify] = useState(false)
  const [password, setPassword] = useState('')
  const [form, setForm] = useState({})

  const { branch, setTitle, user } = props

  useEffect(() => {
    const topbar = {
      title: 'Crear Usuario',
    }
    setTitle(topbar)
    props.getAll('branch', 'GET_BRANCH')
    // eslint-disable-next-line
  }, [])

  const handlePasswordVerify = e => {
    if (password === e.target.value) {
      setPasswordVerify(true)
    } else {
      setPasswordVerify(false)
    }
  }

  const handleForm = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    props.create('users', 'POST_USER', form).then(() => {
      document.getElementById('formUser').reset()
    })
  }

  if (!branch) {
    return <h1>Cargando</h1>
  }

  return (
    <Card title={'Información del usuario'}>
      <form id="formUser" onSubmit={e => handleSubmit(e)}>
        <Input
          type="text"
          name="nss"
          title="N.S.S"
          onChange={e => handleForm(e)}
        />
        <Input
          type="text"
          name="rfc"
          title="RFC"
          onChange={e => handleForm(e)}
        />
        <Input
          type="text"
          name="curp"
          title="CURP"
          onChange={e => handleForm(e)}
        />
        <Input
          type="text"
          name="lastname"
          title="Apellido Paterno"
          onChange={e => handleForm(e)}
        />
        <Input
          type="text"
          name="lastname2"
          title="Apellido Materno"
          onChange={e => handleForm(e)}
        />
        <Input
          type="text"
          name="name"
          title="Nombre"
          onChange={e => handleForm(e)}
        />
        <Input
          type="text"
          name="position"
          title="Puesto"
          onChange={e => handleForm(e)}
        />
        <Input
          type="text"
          name="department"
          title="Departamento"
          onChange={e => handleForm(e)}
        />
        <Input
          type="text"
          name="user"
          title="Usuario"
          onChange={e => handleForm(e)}
        />
        <Input
          type="password"
          name="password"
          title="Contraseña"
          onChange={e => {
            handleForm(e)
            setPassword(e.target.value)
          }}
        />
        <Input
          type="password"
          name="confirmPassword"
          title="Confirmar Contraseña"
          onChange={e => {
            handlePasswordVerify(e)
          }}
        />

        {password !== '' ? (
          passwordVerify ? (
            <Span pass={passwordVerify}>Password Correcto</Span>
          ) : (
            <Span pass={passwordVerify}>
              Los password deben ser iguales
            </Span>
          )
        ) : null}

        <Select
          onChange={e => handleForm(e)}
          name="branch_id"
          required
        >
          <option value="">Seleccione una sucursal</option>
          {branch.map(b => (
            <option key={b.id} value={b.id}>
              {b.name}
            </option>
          ))}
        </Select>

        <Select onChange={e => handleForm(e)} name="role" required>
          <option value="">Seleccione una rol</option>
          <option value="1">Administrador</option>
          <option value="2">Usuario</option>
        </Select>

        {parseInt(user.role) === 0 ? (
          <Select onChange={e => handleForm(e)} name="auto" required>
            <option value="">Seleccione un automatizador</option>
            <option value="0">No</option>

            <option value="1">Si</option>
          </Select>
        ) : null}

        <BarButton>
          {passwordVerify ? (
            <Button type="submit" color="success">
              Crear
            </Button>
          ) : (
            <Button color="success" disabled>
              Crear
            </Button>
          )}
          <Link to="/users">
            <Button color="danger">Cancelar</Button>
          </Link>
        </BarButton>
      </form>
    </Card>
  )
}

const mapStateToProps = state => {
  return {
    user: state.reducerApp.user,
    branch: state.branch.branch,
    themeApp: state.reducerApp.themeApp,
    localization: state.reducerApp.localization,
  }
}

const mapDispatchToProps = {
  create,
  getAll,
  setTitle,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateUser)

const BarButton = styled.div`
  margin-top: 1rem;
`

const Select = styled.select`
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  outline-color: ${({ theme }) => theme.border};
  color: var(--dark);
  background-color: var(--white);
  background-clip: padding-box;
  border-radius: 3px;
  border: ${({ theme }) => theme.border};
  box-shadow: none;
  margin-top: 0.375rem;
  border-style: solid;
  border-radius: 7px;
  width: 100%;
  transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
`

const Span = styled.span`
  color: ${props =>
    props.pass ? props.theme.success : props.theme.danger};
  font-size: 0.875rem;
  line-height: 2.5;
`
