import { CsvBuilder } from 'filefy'

export const exportCsv = (allData, start, end) => {
  const newColumns = [
    { title: 'Nombre', field: 'name' },
    {
      title: 'Fecha',
      field: 'date',
    },
    {
      title: 'Día',
      field: 'day',
    },
    {
      title: 'Horario',
      field: 'schedule',
    },
    {
      title: 'Entrada',
      field: 'start',
    },
    {
      title: 'Salida',
      field: 'end',
    },
    {
      title: 'Entrada comida',
      field: 'startFood',
    },
    {
      title: 'Salida comida',
      field: 'endFood',
    },
    {
      title: 'Estado',
      field: 'status',
    },
  ]

  const items = []

  allData.map(rowData => rowData.map(row => items.push(row)))

  const exportedData = items.map(rowData => {
    return newColumns.map(columnDef => {
      return rowData[columnDef.field]
    })
  })

  new CsvBuilder(
    `registros-${start.format('DD-MM-YYYY')}-${end.format(
      'DD-MM-YYYY'
    )}.csv`
  )
    .setDelimeter(',')
    .setColumns(newColumns.map(columnDef => columnDef.title))
    .addRows(exportedData)
    .exportFile()
}
