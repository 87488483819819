import { BiHomeAlt } from 'react-icons/bi'
import { BsGear } from 'react-icons/bs'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { FiUsers } from 'react-icons/fi'

const role = parseInt(sessionStorage.getItem('role'))

let menu = []

if (role <= 1) {
  menu = [
    {
      title: 'Dashboard',
      icon: <BiHomeAlt />,
      link: '/',
    },
    {
      title: 'Checador de Usuario',
      icon: <AiOutlineClockCircle />,
      link: '/checker/profile',
    },

    {
      title: 'Estatus Usuarios',
      icon: <FiUsers />,
      link: '/checker/status',
    },
    {
      title: 'Checador',
      icon: <AiOutlineClockCircle />,
      link: '/checker',
    },
    {
      title: 'Configuración',
      icon: <BsGear />,
      submenu: [
        {
          title: 'Sucursales',
          link: '/branch',
        },
        {
          title: 'Usuarios',
          link: '/users',
        },
      ],
    },
  ]
} else if (role >= 2) {
  menu = [
    {
      title: 'Checador',
      icon: <AiOutlineClockCircle />,
      link: '/',
    },
  ]
}

export default menu
