import { combineReducers } from 'redux'
import reducerApp from './reducer'
import users from '../pages/Users/reducer'
import checker from '../pages/Checker/reducer'
import branch from '../pages/Branch/reducer'

const reducer = combineReducers({
  reducerApp,
  users,
  checker,
  branch,
})

export default reducer
